import { gql } from '@apollo/client';

export default gql`
  mutation ForceEndTrip($trip_id: ID!) {
    forceEndTrip(trip_id: $trip_id) {
      trip {
        id
      }
    }
  }
`;

import { gql } from '@apollo/client';

import { UserFragment, VehicleFragment } from '../fragments';

export default gql`
  query users($id: ID, $email: String) {
    users(id: $id, email: $email) {
      ...UserFragment
      verifiedDrivingLicense: verification_status
      drivingLicenseFront: drivers_license_front
      drivingLicenseBack: drivers_license_back
      currentReservation: current_reservation {
        id
        expired
        createdAt: created_at
        user {
          ...UserFragment
        }
        vehicle {
          ...VehicleFragment
        }
      }
    }
  }
  ${UserFragment}
  ${VehicleFragment}
`;

import { gql } from '@apollo/client';

export default gql`
  mutation rentPayment($trip_id: ID!) {
    rentPayment(trip_id: $trip_id) {
      success
      amount
      message
      outcome {
        reason
        seller_message
      }
    }
  }
`;

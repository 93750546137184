import { gql } from '@apollo/client';

export default gql`
  mutation resetPassword(
    $email: String!
    $token: String!
    $password: String!
    $password_repeat: String!
  ) {
    resetPassword(
      email: $email
      token: $token
      password: $password
      password_confirm: $password_repeat
    ) {
      email
    }
  }
`;

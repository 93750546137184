import { useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { isLoggedInVar, client } from '<app>/GraphqlClient';
import userStatus from '<app>/models/user';

import { auth } from '<app>/utils';
import { GET_USER_DATA } from './queries';

const useUserData = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const logout = () => {
    auth.removeTokens();
    isLoggedInVar(false);
    client.cache.reset();
  };

  const { data, loading, refetch } = useQuery(GET_USER_DATA);

  useEffect(() => {
    if (isLoggedIn && !data && !loading) {
      refetch();
    }
  }, [data, isLoggedIn, loading, refetch]);

  if (data) {
    const verificationStatus =
      data.user.verification_status === userStatus.VERIFICATION_STATUS_SUCCESS;
    const { role, firstname, lastname } = data.user;

    return { loading, role, verificationStatus, firstname, lastname, isLoggedIn, logout, refetch };
  }
  return {
    loading,
    isLoggedIn,
    logout,
    refetch,
  };
};

export default useUserData;

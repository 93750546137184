import { gql } from '@apollo/client';

import { VehicleFragment, VehicleStateFragment } from '<graphql>/fragments';

export default gql`
  query vehiclesState($id: Int!) {
    vehiclesState(id: $id) {
      ...VehicleStateFragment
      vehicle {
        ...VehicleFragment
      }
    }
  }
  ${VehicleFragment}
  ${VehicleStateFragment}
`;

import { gql } from '@apollo/client';

export default gql`
  query FetchGeozones {
    geozones {
      lat
      lon
      radius
    }
  }
`;

import { gql } from '@apollo/client';

import VehicleFragment from './Vehicle';

export default gql`
  fragment FinishedTripFragment on Trip {
    id
    vehicle {
      ...VehicleFragment
    }
    tripStart: trip_start
    tripEnded: trip_ended
    price
    type
    distance
    duration
    isPaid: is_paid
    comment
    trip_payment_type
  }
  ${VehicleFragment}
`;

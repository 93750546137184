import { gql } from '@apollo/client';

import { VehicleFragment, UserFragment } from '../fragments';

export default gql`
  query reservations($limit: Int, $page: Int!) {
    reservations(page: $page, limit: $limit) {
      total
      perPage: per_page
      currentPage: current_page
      hasMorePages: has_more_pages
      data {
        id
        expired
        createdAt: created_at
        user {
          ...UserFragment
        }
        vehicle {
          ...VehicleFragment
        }
      }
    }
  }
  ${VehicleFragment}
  ${UserFragment}
`;

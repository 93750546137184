import { gql } from '@apollo/client';

import { VehicleFragment } from '<graphql>/fragments';

export default gql`
  mutation updateVehicle(
    $id: ID!
    $vehicleMake: String!
    $vehicleModel: String!
    $vehicleYear: Int!
    $plateNumber: String!
    $paymentMethod: String!
    $vehicleOwnerId: ID!
    $pricePerMinute: Float!
    $pricePerHour: Float!
    $pricePerDay: Float!
    $pricePerThreeDays: Float!
    $pricePerWeek: Float!
    $pricePerFifteenDays: Float!
    $pricePerKm: Float!
    $maxKmPerDay: Float!
    $pricePerExtraKm: Float!
    $pricePerHourAbroad: Float!
    $pricePerDayAbroad: Float!
    $maxKmPerDayAbroad: Float!
    $pricePerExtraKmAbroad: Float!
    $state: String!
    $image: Upload
    $vin: String!
    $vehicleType: String!
    $fuelType: String!
    $transmission: String!
    $seats: Int
    $doors: Int
    $height: Float
    $width: Float
    $length: Float
    $customFeatures: [String]
  ) {
    updateVehicle(
      id: $id
      vehicle_make: $vehicleMake
      vehicle_model: $vehicleModel
      vehicle_year: $vehicleYear
      plate_number: $plateNumber
      payment_method: $paymentMethod
      vehicle_owner_id: $vehicleOwnerId
      price_per_minute: $pricePerMinute
      price_per_hour: $pricePerHour
      price_per_day: $pricePerDay
      price_per_three_days: $pricePerThreeDays
      price_per_week: $pricePerWeek
      price_per_fifteen_days: $pricePerFifteenDays
      price_per_km: $pricePerKm
      max_km_per_day: $maxKmPerDay
      price_per_extra_km: $pricePerExtraKm
      price_per_hour_abroad: $pricePerHourAbroad
      price_per_day_abroad: $pricePerDayAbroad
      max_km_per_day_abroad: $maxKmPerDayAbroad
      price_per_extra_km_abroad: $pricePerExtraKmAbroad
      state: $state
      image: $image
      vin: $vin
      vehicle_type: $vehicleType
      fuel_type: $fuelType
      transmission: $transmission
      seats: $seats
      doors: $doors
      height: $height
      width: $width
      length: $length
      custom_features: $customFeatures
    ) {
      ...VehicleFragment
    }
  }
  ${VehicleFragment}
`;

import { gql } from '@apollo/client';

export default gql`
  query invoices {
    invoices {
      id
      name
      default_email
      is_sent
      invoice_date
    }
  }
`;

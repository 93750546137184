import { gql } from '@apollo/client';

export default gql`
  mutation addPaymentCard($id: String!) {
    createCard(payment_id: $id) {
      id
      card_number
      expiration_year
      expiration_month
    }
  }
`;

import { gql } from '@apollo/client';

import { VehicleFragment } from '<graphql>/fragments';

export default gql`
  mutation updateVehicleForOwner(
    $id: ID!
    $vehicleMake: String!
    $vehicleModel: String!
    $vehicleYear: Int!
    $plateNumber: String!
    $paymentMethod: String!
    $pricePerMinute: Float!
    $pricePerHour: Float!
    $pricePerDay: Float!
    $pricePerKm: Float!
    $maxKmPerDay: Float!
    $pricePerExtraKm: Float!
    $pricePerHourAbroad: Float!
    $pricePerDayAbroad: Float!
    $maxKmPerDayAbroad: Float!
    $pricePerExtraKmAbroad: Float!
    $state: String!
    $image: Upload
    $vin: String!
  ) {
    updateVehicleForOwner(
      id: $id
      vehicle_make: $vehicleMake
      vehicle_model: $vehicleModel
      vehicle_year: $vehicleYear
      plate_number: $plateNumber
      payment_method: $paymentMethod
      price_per_minute: $pricePerMinute
      price_per_hour: $pricePerHour
      price_per_day: $pricePerDay
      price_per_km: $pricePerKm
      max_km_per_day: $maxKmPerDay
      price_per_extra_km: $pricePerExtraKm
      price_per_hour_abroad: $pricePerHourAbroad
      price_per_day_abroad: $pricePerDayAbroad
      max_km_per_day_abroad: $maxKmPerDayAbroad
      price_per_extra_km_abroad: $pricePerExtraKmAbroad
      state: $state
      image: $image
      vin: $vin
    ) {
      ...VehicleFragment
    }
  }
  ${VehicleFragment}
`;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

const SPINNER_TYPES = {
  smallWhite: 'spinner__small-white',
  miniWhite: 'spinner__mini-white',
  big: 'spinner__big',
  small: 'spinner__small',
};

function Spinner({ type, label, labelTextProps, isAbsolute }) {
  return (
    <div
      className={cx('w-full h-full top-0 left-0 overlay', {
        'flex justify-center items-center flex-col': type === 'big',
        absolute: isAbsolute,
      })}
    >
      <div className={cx('spinner', SPINNER_TYPES[type])}>
        <div className="spinner-element" />
        <div className="spinner-element" />
        <div className="spinner-element" />
        <div className="spinner-element" />
        <div className="spinner-element" />
        <div className="spinner-element" />
        <div className="spinner-element" />
        <div className="spinner-element" />
        <div className="spinner-element" />
        <div className="spinner-element" />
        <div className="spinner-element" />
        <div className="spinner-element" />
      </div>
      {label ? (
        <div className="block mt-3 text-center">
          <span className={cx('w-full', labelTextProps)}>{label}</span>
        </div>
      ) : null}
    </div>
  );
}
Spinner.propTypes = {
  type: PropTypes.oneOf(Object.keys(SPINNER_TYPES)),
  label: PropTypes.string,
  labelTextProps: PropTypes.string,
  isAbsolute: PropTypes.bool,
};

Spinner.defaultProps = {
  type: 'big',
  labelTextProps: '',
  isAbsolute: true,
};

export default Spinner;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '<app>/components';

function Paginator({ hasMorePages, setPage, page }) {
  return (
    <div className="flex w-64 flex-col md:flex-row md:rounded-t-none md:pt-6 md:pb-12 md:justify-around relative md:w-full">
      {page !== 1 && (
        <Button
          styleType="gray"
          className="text-200 mx-2 mt-4"
          onClick={() => setPage((prev) => prev - 1)}
        >
          Ankstesnis puslapis
        </Button>
      )}

      {hasMorePages && (
        <Button className="text-200 mx-2 mt-4" onClick={() => setPage((prev) => prev + 1)}>
          Kitas puslapis
        </Button>
      )}
    </div>
  );
}

Paginator.propTypes = {
  hasMorePages: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

export default Paginator;

import { gql } from '@apollo/client';

export default gql`
  fragment VehicleOwnerFragment on User {
    id
    firstname
    lastname
    email
    phone
    companyName: company_name
    companyCode: company_code
    companyAddress: company_address
    companyVat: company_vat
    numberOfVehicles: number_of_vehicles
    enabled
  }
`;

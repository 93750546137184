import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const OverlayModal = ({ header, isOpen, handleCloseModal, children }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCloseModal}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle max-w-sm w-full sm:p-6 rounded-50">
              <div className="flex flex-col">
                <div className="flex flex-row justify-between">
                  <p className="text-300 sm:text-400">{header}</p>
                  <button
                    type="button"
                    className="ml-auto cursor-pointer focus:outline-none"
                    onClick={handleCloseModal}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleCloseModal();
                    }}
                  >
                    <img src="<assets>/icons/close.svg" alt="close icon" />
                  </button>
                </div>
                <div className="text-center mt-5">{children}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

OverlayModal.propTypes = {
  header: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default OverlayModal;

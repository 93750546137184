import { gql } from '@apollo/client';

export default gql`
  mutation manuallyLockVehicle($id: ID!) {
    manuallyLockVehicle(id: $id) {
      trip {
        id
      }
    }
  }
`;

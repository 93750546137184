import { gql } from '@apollo/client';

import { VehicleFragment } from '../fragments';

export default gql`
  query reservationsForOwner($limit: Int, $page: Int!) {
    reservations(page: $page, limit: $limit) {
      total
      perPage: per_page
      currentPage: current_page
      hasMorePages: has_more_pages
      data {
        id
        expired
        createdAt: created_at
        vehicle {
          ...VehicleFragment
        }
      }
    }
  }
  ${VehicleFragment}
`;

import { gql } from '@apollo/client';

export const GET_USER_DATA = gql`
  query FetchUser {
    user {
      id
      role
      verification_status
      firstname
      lastname
    }
  }
`;

export const SORTING_TYPES = { DEFAULT: 0, DESCENDING: 1, ASCENDING: 2 };

export const search = (searchValue, dataRow, searchByKeys) => {
  const searchWords = searchValue.toLowerCase().split(' ');
  return Object.entries(dataRow).some((keys) =>
    searchWords.some(
      (word) =>
        searchByKeys?.includes(keys[0]) && keys[1]?.toLowerCase().includes(word) && word.length > 0
    )
  );
};
export const getCharCodeIfString = (element) =>
  typeof element === 'string' ? element.charCodeAt(0) : element;

export const sort = (data, sortingIteration, columnToSortBy) => {
  if (columnToSortBy && sortingIteration === SORTING_TYPES.DESCENDING) {
    return [...data].sort(
      (a, b) => getCharCodeIfString(a[columnToSortBy]) - getCharCodeIfString(b[columnToSortBy])
    );
  }
  if (columnToSortBy && sortingIteration === SORTING_TYPES.ASCENDING) {
    return [...data].sort(
      (a, b) => getCharCodeIfString(b[columnToSortBy]) - getCharCodeIfString(a[columnToSortBy])
    );
  }
  return data;
};

export const translateStatus = (item, key, statuses) => {
  if (item === undefined || item === null || item === '') {
    return '-';
  }

  const status = item.toString().replace(/\s/g, '');

  // Check the default dictionary first
  if (statuses.default[status]) {
    return statuses.default[status];
  }

  // If no translation found in default, check if there's a dictionary for the key
  if (statuses[key] && statuses[key][status]) {
    return statuses[key][status];
  }

  // Check if the item matches any key in the entire STATUSES dictionary
  for (const dictKey in statuses) {
    if (statuses[dictKey][status]) {
      return statuses[dictKey][status];
    }
  }

  // If no match found, return the original item
  return item;
};

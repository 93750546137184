import { gql } from '@apollo/client';

import { VehicleFragment, VehicleStateFragment } from '../fragments';

export default gql`
  query activeReservation {
    activeReservation {
      id
      vehicleInfo: vehicle_info {
        ...VehicleStateFragment
        vehicle {
          ...VehicleFragment
        }
      }
      timeRemaining: time_remaining
      tripType: trip_type
      createdByAdmin: created_by_admin
    }
  }
  ${VehicleFragment}
  ${VehicleStateFragment}
`;

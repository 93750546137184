import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.scss';

const RadioButton = React.forwardRef(({ label, className, ...restProps }, ref) => {
  return (
    <div className={cx(className, 'flex')}>
      <label className="custom-radio-button relative cursor-pointer">
        <input type="radio" {...restProps} ref={ref} className="relative opacity-0 top-0 left-0 " />
        <span className="custom-radio-button_styled block absolute top-0 left-0 rounded-full border-black border-solid border-3" />
      </label>
      {label && <div className="ml-4">{label}</div>}
    </div>
  );
});

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default RadioButton;

import { gql } from '@apollo/client';

import { UserFragment, UserVerificationFragment } from '../fragments';

export default gql`
  mutation updateUser(
    $id: ID
    $firstname: String!
    $lastname: String!
    $password: String
    $phone: String!
    $verifiedDrivingLicense: String
    $isJuridical: Boolean!
    $companyName: String
    $companyCode: String
    $companyAddress: String
    $companyVat: String
    $address: String!
  ) {
    updateUser(
      id: $id
      firstname: $firstname
      lastname: $lastname
      password: $password
      phone: $phone
      verification_status: $verifiedDrivingLicense
      is_juridical: $isJuridical
      company_name: $companyName
      company_code: $companyCode
      company_address: $companyAddress
      company_vat: $companyVat
      address: $address
    ) {
      ...UserFragment
      ...UserVerificationFragment
    }
  }
  ${UserFragment}
  ${UserVerificationFragment}
`;

import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

function PageLayout({ children }) {
  return (
    <div className="layout">
      <div className="max-content pb-5">{children}</div>
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;

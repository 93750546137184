import { gql } from '@apollo/client';

import { FinishedTripFragment } from '../fragments';

export default gql`
  query trips($limit: Int, $page: Int!) {
    trips(page: $page, limit: $limit) {
      total
      perPage: per_page
      currentPage: current_page
      hasMorePages: has_more_pages
      data {
        ...FinishedTripFragment
      }
    }
  }
  ${FinishedTripFragment}
`;

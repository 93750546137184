import { ApolloClient, InMemoryCache, ApolloLink, makeVar } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { setContext } from 'apollo-link-context';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { auth } from './utils';

const httpLink = createUploadLink({ uri: process.env.APP_API_URL });
const { accessToken: isLoggedIn } = auth.getAccessTokens();
export const isLoggedInVar = makeVar(!!isLoggedIn);

const authLink = setContext((_, { headers }) => {
  const { accessToken } = auth.getAccessTokens();
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  };
});

const refreshTokenLink = new TokenRefreshLink({
  accessTokenField: 'refreshToken',
  isTokenValidOrUndefined: () => {
    try {
      const { accessToken, expirationTime } = auth.getAccessTokens();
      if (!accessToken) return true;
      const expires = new Date(expirationTime * 1000);
      return Date.now() < expires;
    } catch {
      return false;
    }
  },
  fetchAccessToken: async () => {
    const { refreshToken } = auth.getAccessTokens();

    const resp = await fetch(process.env.APP_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `mutation RefreshToken { refreshToken(refresh_token: "${refreshToken}") { access_token refresh_token } }`,
      }),
    });

    return resp.json();
  },
  handleResponse: async (response) => {
    const { data: refreshToken } = response;
    return refreshToken;
  },
  handleFetch: (newTokens) => {
    const { access_token: accessToken, refresh_token: refreshToken } = newTokens;

    if (!accessToken || !refreshToken) {
      return auth.removeTokens();
    }

    return auth.setTokens(newTokens);
  },
  handleError: () => {
    auth.removeTokens();
    return window.location.reload();
  },
});

export const client = new ApolloClient({
  link: ApolloLink.from([refreshTokenLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isLoggedIn() {
            return isLoggedInVar();
          },
        },
      },
    },
  }),
});

export default client;

import { gql } from '@apollo/client';

export default gql`
  mutation manuallyUnlockVehicle($id: ID!) {
    manuallyUnlockVehicle(id: $id) {
      trip {
        id
      }
    }
  }
`;

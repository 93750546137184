import { gql } from '@apollo/client';

import { VehicleFragment, VehicleStateFragment } from '../fragments';

export default gql`
  query tripInformation {
    tripInformation {
      distance
      duration
      tripPrice: trip_price
      tripType: type
      vehicleInfo: vehicle_info {
        ...VehicleStateFragment
        vehicle {
          ...VehicleFragment
        }
      }
    }
  }
  ${VehicleFragment}
  ${VehicleStateFragment}
`;

import { gql } from '@apollo/client';

export default gql`
  fragment VehicleStateFragment on VehicleState {
    lat
    lon
    alt
    speed
    fuelLevel: fuel_level
  }
`;

import { gql } from '@apollo/client';

import { VehicleOwnerFragment } from '../fragments';

export default gql`
  mutation updateVehicleOwner(
    $id: Int!
    $firstname: String!
    $lastname: String!
    $password: String
    $companyName: String!
    $companyCode: String!
    $companyAddress: String!
    $companyVat: String!
    $email: String!
    $phone: String!
    $enabled: Boolean!
  ) {
    updateVehicleOwner(
      id: $id
      firstname: $firstname
      lastname: $lastname
      password: $password
      email: $email
      phone: $phone
      company_name: $companyName
      company_code: $companyCode
      company_address: $companyAddress
      company_vat: $companyVat
      enabled: $enabled
    ) {
      ...VehicleOwnerFragment
      __typename
    }
  }
  ${VehicleOwnerFragment}
`;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.scss';

const Input = React.forwardRef(
  ({ wrapperClassName, className, errorClassName, error, reducedSize, ...restProps }, ref) => {
    return (
      <div className={cx('w-full', wrapperClassName)}>
        <input
          {...restProps}
          ref={ref}
          className={cx(
            reducedSize ? 'py-2 px-4' : 'py-3 px-6',
            'mb-1 leading-none placeholder-gray-600 text-gray-100 w-full',
            className
          )}
        />
        {error && (
          <span className={cx('text-red-200 text-150', errorClassName)}>{error.message}</span>
        )}
      </div>
    );
  }
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  error: PropTypes.shape({ message: PropTypes.string }),
  reducedSize: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
};

export default Input;

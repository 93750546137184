import { gql } from '@apollo/client';

export default gql`
  mutation vehicleReservationByAdmin($user_id: ID!, $vehicle_id: ID!, $trip_type: trip_type!) {
    vehicleReservationByAdmin(user_id: $user_id, vehicle_id: $vehicle_id, trip_type: $trip_type) {
      id
      timeRemaining: time_remaining
      vehicle_info {
        vehicle {
          id
          vin
        }
      }
      tripType: trip_type
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation changeVehicleReservation($user_id: ID!, $vehicle_id: ID!, $trip_type: trip_type!) {
    changeVehicleReservationMutation(
      user_id: $user_id
      vehicle_id: $vehicle_id
      trip_type: $trip_type
    ) {
      id
      vehicle_info {
        vehicle {
          id
          vin
        }
      }
      user {
        id
      }
      time_remaining
      trip_type
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Checkbox = React.forwardRef(({ label, link, ...restProps }, ref) => {
  return (
    <div className="flex">
      <label className="checkbox relative cursor-pointer">
        <input
          type="checkbox"
          {...restProps}
          ref={ref}
          className="relative opacity-0 top-0 left-0 "
        />
        <span className="checkbox_styled block absolute top-0 left-0 rounded-full border-black border-solid border-3" />
      </label>
      {label && <div className="ml-4 text-left">{label}</div>}
      {link && <div className="ml-4 text-left">{link}</div>}
    </div>
  );
});

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  link: PropTypes.node,
};

export default Checkbox;

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import { RentPaymentMutation } from '<graphql>/mutations';
import { GetInvoice } from '<graphql>/queries';
import { formatSecondsToHours, parseGraphQlErrors } from '<app>/utils';
import { Loader, Button } from '<app>/components';
import { ContextualAlert } from '<src>/form';

function ReservationCard({ trip, refetchTrips }) {
  const [showConfirmBox, setConfirmBoxVisibility] = useState(false);

  const [
    getInvoice,
    { error: invoiceError, loading: invoiceLoading, data: invoiceData },
  ] = useLazyQuery(GetInvoice, {
    onCompleted: (res) => {
      window.open(res.invoice);
    },
  });

  const [rentPayment, { data, loading, error }] = useMutation(RentPaymentMutation, {
    onCompleted: () => refetchTrips(),
  });

  useEffect(() => {
    if (data?.rentPayment?.success != null) {
      setConfirmBoxVisibility(true);
      const timeout = setTimeout(() => setConfirmBoxVisibility(false), 5000);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [data, setConfirmBoxVisibility]);

  return (
    <>
      {invoiceError && (
        <ContextualAlert type="error">Nepavyko sugeneruoti sąskaitos.</ContextualAlert>
      )}
      {error &&
        parseGraphQlErrors(error).map((message) => (
          <ContextualAlert key={message} type="error">
            Nesėkmingas apmokėjimas. Priežastis: {message}
          </ContextualAlert>
        ))}
      {showConfirmBox && (
        <>
          {data.rentPayment.success ? (
            <ContextualAlert type="success">
              Sėkmingai apmokėta kelionė. Suma apmokėta: {data.rentPayment.amount}€
            </ContextualAlert>
          ) : (
            <ContextualAlert type="error">
              Kelionė neapmokėta. Priežastis: {data.rentPayment.message}
            </ContextualAlert>
          )}
        </>
      )}
      <div className="rounded-100 bg-gray-300 flex flex-col xl:flex-row justify-between w-full xl:w-fit my-3">
        <div className="flex flex-col xl:flex-row justify-between py-4 px-1 xl:px-10">
          <div className="flex flex-row pr-4 items-center justify-center xl:items-start xl:justify-start mt-1">
            <img src="<assets>/icons/mb.png" alt="MB" className="w-16 h-16 object-contain" />
            <div className="flex flex-col ml-4">
              <h2 className="font-semibold text-400 xl:text-500 leading-none xl:leading-100">
                {`${trip.vehicle.vehicleMake}, ${trip.vehicle.vehicleModel}, ${trip.vehicle.vehicleYear} m.`}
              </h2>
              <p className="text-300 xl:text-400 leading-200">{trip.vehicle.plateNumber}</p>
            </div>
          </div>

          <img
            src={trip.vehicle.image || '<assets>/images/bmw-car.png'}
            alt="car"
            className="object-contain py-6 block xl:hidden max-h-96"
          />

          <div className="flex flex-col px-8 py-3 xl:py-0 self-center">
            <div className="flex flex-col mb-3">
              <h3 className="text-200">Kelionės pradžia:</h3>
              <p className="font-semibold text-200">{trip.tripStart}</p>
            </div>
            <div className="flex flex-col">
              <h3 className="text-200">Kelionės pabaiga:</h3>
              <p className="font-semibold text-200">{trip.tripEnded}</p>
            </div>
          </div>

          <div className="flex flex-row justify-center items-center">
            <div className="flex flex-col items-center px-4">
              <h3>Trukmė:</h3>
              <div className="border-4 h-20 w-20 justify-center items-center rounded-full flex flex-col border-black">
                <span className="text-200">{formatSecondsToHours(trip.duration)}</span>
                <span className="text-100">H</span>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center px-4">
              <h3>Kaina:</h3>
              <div className="border-2 h-20 w-20 justify-center items-center rounded-full flex flex-col bg-purple-100">
                <span className="text-300 text-white">{trip.price}</span>
                <span className="text-100 text-white">EUR</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center px-4">
            <h3>Apmokėta:</h3>
            <div
              className={cx(
                'border-2 h-20 w-20 justify-center items-center rounded-full flex flex-col',
                trip.isPaid ? 'bg-green-100' : 'bg-red-300'
              )}
            >
              {loading ? (
                <Loader />
              ) : (
                <>
                  <span className="text-300 text-white">{trip.isPaid ? 'Taip' : 'Ne'}</span>
                  {!trip.isPaid && (
                    <button
                      className="self-end h-10 w-full text-200 font-semibold text-white border-t-4"
                      type="button"
                      onClick={() =>
                        rentPayment({
                          variables: {
                            trip_id: trip.id,
                          },
                        })
                      }
                    >
                      Mokėti
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <img
          src={trip.vehicle.image}
          alt={`${trip.vehicle.vehicleMake} ${trip.vehicle.vehicleModel} ${trip.vehicle.vehicleYear}`}
          className="p-3 object-contain hidden xl:block max-w-xs h-fit self-center"
        />
        <Button
          className="self-end h-12 w-40 xl:w-56 bg-purple-100 rounded-none rounded-br-100 rounded-tl-100 text-300 font-semibold text-white"
          type="button"
          onClick={() =>
            invoiceData
              ? window.open(invoiceData.invoice)
              : getInvoice({ variables: { id: trip?.id } })
          }
          loading={invoiceLoading}
        >
          <p className="text-250">Atsisiųsti sąskaitą</p>
        </Button>
      </div>
    </>
  );
}

ReservationCard.propTypes = {
  trip: PropTypes.shape({
    id: PropTypes.string.isRequired,
    vehicle: PropTypes.shape({
      vehicleMake: PropTypes.string.isRequired,
      vehicleModel: PropTypes.string.isRequired,
      vehicleYear: PropTypes.number.isRequired,
      plateNumber: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired,
    tripStart: PropTypes.string.isRequired,
    tripEnded: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    distance: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    isPaid: PropTypes.bool.isRequired,
  }),
  refetchTrips: PropTypes.func.isRequired,
};

export default ReservationCard;

import { gql } from '@apollo/client';

export default gql`
  fragment UserFragment on User {
    id
    firstname
    lastname
    email
    phone
    address: address
    isJuridical: is_juridical
    companyName: company_name
    companyCode: company_code
    companyAddress: company_address
    companyVat: company_vat
    hasPaymentCard: has_payment_card
    activeRent: active_rent {
      id
      vehicle {
        id
        plateNumber: plate_number
      }
    }
  }
`;

import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PageLayout, SideBar, Spinner } from '<src>/App/components';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import pathnames from '<app>/pathnames';
import AdminRoute from './AdminRoute';
import VehicleOwnerRoute from './VehicleOwnerRoute';
import PublicRoute from './PublicRoute';
import { useUserData } from '<app>/hooks';

function Router() {
  const { loading } = useUserData();

  if (loading) return <Spinner />;
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <VehicleOwnerRoute path={pathnames.VEHICLE_OWNER_PREFIX}>
            <SideBar>
              <Switch>
                <VehicleOwnerRoute
                  exact
                  path={pathnames.VEHICLE_OWNER_AUTO_LIST}
                  component={lazy(() => import('<src>/App/pages/vehicleOwners/VehicleList'))}
                />
                <VehicleOwnerRoute
                  exact
                  path={pathnames.VEHICLE_OWNER_RENT_LIST}
                  component={lazy(() => import('<src>/App/pages/vehicleOwners/RentList'))}
                />
                <VehicleOwnerRoute
                  exact
                  path={pathnames.VEHICLE_OWNER_EDIT_VEHICLE(':id')}
                  component={lazy(() => import('<src>/App/pages/vehicleOwners/EditVehicle'))}
                />
                <VehicleOwnerRoute path={pathnames.VEHICLE_OWNER_PREFIX} />
              </Switch>
            </SideBar>
          </VehicleOwnerRoute>
          <AdminRoute path={pathnames.ADMIN_PREFIX}>
            <SideBar>
              <Switch>
                <AdminRoute
                  exact
                  path={pathnames.ADMIN_AUTO_LIST}
                  component={lazy(() => import('<src>/App/pages/admin/VehicleList'))}
                />
                <AdminRoute
                  exact
                  path={pathnames.ADMIN_CLIENT_LIST}
                  component={lazy(() => import('<src>/App/pages/admin/ClientList'))}
                />
                <AdminRoute
                  exact
                  path={pathnames.ADMIN_RENT_LIST}
                  component={lazy(() => import('<src>/App/pages/admin/RentList'))}
                />
                <AdminRoute
                  exact
                  path={pathnames.ADMIN_OWNERS_LIST}
                  component={lazy(() => import('<src>/App/pages/admin/VehicleOwnerList'))}
                />
                <AdminRoute
                  exact
                  path={pathnames.ADMIN_INVOICE_LIST}
                  component={lazy(() => import('<src>/App/pages/admin/InvoiceList'))}
                />
                <AdminRoute
                  exact
                  path={pathnames.ADMIN_CREATE_OWNER}
                  component={lazy(() => import('<src>/App/pages/admin/CreateOwner'))}
                />
                <AdminRoute
                  exact
                  path={pathnames.ADMIN_CREATE_VEHICLE}
                  component={lazy(() => import('<src>/App/pages/admin/CreateVehicle'))}
                />
                <AdminRoute
                  exact
                  path={pathnames.ADMIN_EDIT_VEHICLE(':id')}
                  component={lazy(() => import('<src>/App/pages/admin/EditVehicle'))}
                />
                <AdminRoute
                  exact
                  path={pathnames.ADMIN_EDIT_CLIENT(':id')}
                  component={lazy(() => import('<src>/App/pages/admin/EditClient'))}
                />
                <AdminRoute
                  exact
                  path={pathnames.ADMIN_EDIT_OWNER(':id')}
                  component={lazy(() => import('<src>/App/pages/admin/EditOwner'))}
                />
                <AdminRoute path={pathnames.ADMIN_PREFIX} />
              </Switch>
            </SideBar>
          </AdminRoute>
          <PageLayout>
            <Switch>
              <UnauthenticatedRoute
                exact
                path={pathnames.LOGIN}
                component={lazy(() => import('<src>/App/pages/Login'))}
              />
              <PublicRoute
                path="/"
                component={() => {
                  window.location.href = 'https://rentit.lt/';
                }}
              />
              <PublicRoute
                path="*"
                component={() => {
                  window.location.href = 'https://rentit.lt/';
                }}
              />
            </Switch>
          </PageLayout>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;

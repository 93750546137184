import React from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert'; // Import
import cx from 'classnames';
import './confirm-alert.scss'; // Import css

import { Button } from '<app>/components';

const ActionButton = ({
  id,
  onClick,
  isLoading,
  label,
  confirmable,
  confirmLabel,
  noDefaultWrapStyle,
  danger,
}) => {
  return (
    <div className={cx({ 'flex justify-center': !noDefaultWrapStyle })}>
      <Button
        styleType={danger ? 'danger' : undefined}
        resetDefaultClassNames
        className="rounded-100 text-100 p-2 mt-2"
        onClick={() => {
          if (confirmable) {
            confirmAlert({
              title: 'Patvirtinimas',
              message: confirmLabel,
              buttons: [
                {
                  label: 'Taip',
                  onClick: () =>
                    onClick({
                      variables: {
                        id,
                      },
                    }),
                },
                {
                  label: 'Ne',
                },
              ],
            });
          } else {
            onClick({
              variables: {
                id,
              },
            });
          }
        }}
        loading={isLoading}
      >
        {label}
      </Button>
    </div>
  );
};

ActionButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  confirmable: PropTypes.bool,
  confirmLabel: PropTypes.string,
  noDefaultWrapStyle: PropTypes.bool,
  danger: PropTypes.bool,
};

ActionButton.defaultProps = {
  confirmLabel: 'Ar norite tęsti?',
  confirmable: false,
  danger: false,
};

export default ActionButton;

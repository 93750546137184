import { gql } from '@apollo/client';

export default gql`
  query MobileIDAuth($token: String!) {
    mobileIdAuth(token: $token) {
      is_valid
      errors
    }
  }
`;

const parseGraphQlErrors = (errors) => {
  const validationErrorMessages = [];
  if (errors?.graphQLErrors) {
    const validation = errors?.graphQLErrors?.map((error) => error?.extensions?.validation);
    if (validation?.[0]) {
      validation.forEach((element) => {
        const message = Object.values(element).map((value) => value?.[0]);
        validationErrorMessages.push(message?.[0]);
      });
    }
  }
  if (validationErrorMessages?.length > 0) return validationErrorMessages;
  return errors?.graphQLErrors?.map(({ message, debugMessage }) => debugMessage ?? message);
};

export default parseGraphQlErrors;

import { gql } from '@apollo/client';

import { UserFragment } from '../fragments';

export default gql`
  query FetchUser {
    user {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

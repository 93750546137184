import { gql } from '@apollo/client';

export default gql`
  query SmartIDCode {
    smartIdCode {
      code
      token
    }
  }
`;

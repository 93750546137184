import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from '../Button';

function Modal({
  label,
  acceptText,
  declineText,
  acceptHandler,
  declineHandler,
  showModal,
  children,
}) {
  return (
    <div className={cx('w-full h-full absolute', { hidden: !showModal })}>
      <div className="absolute bg-black opacity-75 h-full w-full z-10" />
      <div className="flex flex-1 h-full justify-center">
        <div className="bg-white flex w-2/3 sm:w-1/2 lg:w-1/4 self-center px-6 py-4 flex-col rounded-50 z-20">
          <p className="text-400 mb-2 sm:text-center">{label}</p>
          {children}
          <div className="mt-4">
            <Button
              resetDefaultClassNames
              className="rounded-100 px-4 py-1 mr-4 w-full mb-4 md:w-fit md:mb-0"
              onClick={acceptHandler}
            >
              {acceptText}
            </Button>
            <Button
              resetDefaultClassNames
              className="rounded-100 px-4 py-1 w-full md:w-fit"
              onClick={declineHandler}
            >
              {declineText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  label: PropTypes.string.isRequired,
  acceptText: PropTypes.string.isRequired,
  declineText: PropTypes.string.isRequired,
  acceptHandler: PropTypes.func.isRequired,
  declineHandler: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;

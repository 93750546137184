import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';

momentDurationFormat(moment);

const formatSecondsToHours = (seconds) => {
  return moment.duration(seconds, 'seconds').format('D [d.] HH [val.] mm [min]', { trim: false });
};

export default formatSecondsToHours;

import { gql } from '@apollo/client';

import { UserVerificationFragment } from '../fragments';

export default gql`
  query FetchUser {
    user {
      ...UserVerificationFragment
    }
  }
  ${UserVerificationFragment}
`;

import { gql } from '@apollo/client';

import { VehicleOwnerFragment } from '../fragments';

export default gql`
  query vehicleOwner($id: Int) {
    vehicleOwner(id: $id) {
      ...VehicleOwnerFragment
    }
  }
  ${VehicleOwnerFragment}
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { checkTextClassname } from '<app>/utils';

const LINK_TYPES = {
  purple: 'bg-purple-100 text-white border-2 border-purple-100',
  black: 'border-2 text-black border-black',
};

function LinkButton({ to, styleType, children, className, onClick, resetDefaultClassNames }) {
  return (
    <Link
      // eslint-disable-next-line react/button-has-type
      onClick={onClick}
      to={to}
      className={cx(
        { 'rounded-100 md:w-36 py-1 text-center self-center': !resetDefaultClassNames },
        className,
        LINK_TYPES[styleType],
        {
          'text-300': !checkTextClassname(className),
        }
      )}
    >
      {children}
    </Link>
  );
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  styleType: PropTypes.oneOf(Object.keys(LINK_TYPES)),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  resetDefaultClassNames: PropTypes.bool,
};

LinkButton.defaultProps = {
  styleType: 'purple',
  type: 'button',
};

export default LinkButton;

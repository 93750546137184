import { gql } from '@apollo/client';

import VehicleFragment from './Vehicle';
import VehicleStateFragment from './VehicleState';

export default gql`
  fragment TripFragment on Trip {
    id
    vehicleInfo: vehicle_info {
      ...VehicleStateFragment
      vehicle {
        ...VehicleFragment
      }
    }
    trip_start
    trip_ended
    price
    type
    user {
      id
    }
  }
  ${VehicleFragment}
  ${VehicleStateFragment}
`;

import jwtDecode from 'jwt-decode';

const CONSTANTS = {
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
};

function removeTokens() {
  try {
    localStorage.removeItem(CONSTANTS.accessToken);
    return localStorage.removeItem(CONSTANTS.refreshToken);
  } catch (e) {
    return false;
  }
}

function getAccessTokens() {
  try {
    const accessToken = localStorage.getItem(CONSTANTS.accessToken);
    const refreshToken = localStorage.getItem(CONSTANTS.refreshToken);

    if (!accessToken || !refreshToken) return {};

    const { exp } = jwtDecode(accessToken);

    return {
      accessToken,
      refreshToken,
      expirationTime: exp,
    };
  } catch (e) {
    return false;
  }
}

function setTokens(tokens) {
  const { access_token: accessToken, refresh_token: refreshToken } = tokens;
  if (!accessToken || !refreshToken) {
    return false;
  }

  try {
    localStorage.setItem(CONSTANTS.accessToken, accessToken);
    localStorage.setItem(CONSTANTS.refreshToken, refreshToken);
    return true;
  } catch (e) {
    return false;
  }
}

export default { removeTokens, getAccessTokens, setTokens };

import { gql } from '@apollo/client';

import { VehicleFragment, VehicleStateFragment } from '<graphql>/fragments';

export default gql`
  query vehiclesStates {
    vehiclesStates {
      ...VehicleStateFragment
      vehicle {
        ...VehicleFragment
      }
    }
  }
  ${VehicleFragment}
  ${VehicleStateFragment}
`;

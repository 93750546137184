import { gql } from '@apollo/client';

import { VehicleFragment, VehicleOwnerFragment } from '../fragments';

export default gql`
  query vehicle($id: ID, $status: String, $plateNumber: String) {
    vehicle(id: $id, status: $status, plateNumber: $plateNumber) {
      ...VehicleFragment
      vehicleOwner: vehicle_owner {
        ...VehicleOwnerFragment
      }
    }
  }
  ${VehicleFragment}
  ${VehicleOwnerFragment}
`;

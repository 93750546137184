import { gql } from '@apollo/client';

import { TripFragment, CommandFragment } from '../fragments';

export default gql`
  mutation UnlockVehicleMutation($id: ID!, $trip_type: trip_type!) {
    unlockVehicle(id: $id, trip_type: $trip_type) {
      trip {
        ...TripFragment
      }
      command {
        ...CommandFragment
      }
    }
  }
  ${TripFragment}
  ${CommandFragment}
`;

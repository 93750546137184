import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUserData } from '<app>/hooks';
import pathnames from '<app>/pathnames';

function UnauthenticatedRoute({ ...props }) {
  const { role, verificationStatus } = useUserData();

  if (role) {
    if (!verificationStatus) {
      return <Redirect to={pathnames.IDENTIFICATION} />;
    }
    return <Redirect to={pathnames.LANDING} />;
  }

  return <Route {...props} />;
}

export default UnauthenticatedRoute;

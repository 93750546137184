import { gql } from '@apollo/client';

export default gql`
  query MobileIDCode {
    mobileIdCode {
      code
      token
    }
  }
`;

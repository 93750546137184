import { gql } from '@apollo/client';

export default gql`
  fragment VehicleFragment on Vehicle {
    id
    vehicleMake: vehicle_make
    vehicleModel: vehicle_model
    vehicleYear: vehicle_year
    fuelLeft: fuel_left
    plateNumber: plate_number
    vehicleOwnerId: vehicle_owner_id
    vehicleOwnerCompany: vehicle_owner_company
    paymentMethod: payment_method
    image
    pricePerMinute: price_per_minute
    pricePerHour: price_per_hour
    pricePerDay: price_per_day
    pricePerThreeDays: price_per_three_days
    pricePerWeek: price_per_week
    pricePerFifteenDays: price_per_fifteen_days
    pricePerKm: price_per_km
    maxKmPerDay: max_km_per_day
    pricePerExtraKm: price_per_extra_km
    pricePerHourAbroad: price_per_hour_abroad
    pricePerDayAbroad: price_per_day_abroad
    maxKmPerDayAbroad: max_km_per_day_abroad
    pricePerExtraKmAbroad: price_per_extra_km_abroad
    status
    state
    vin
    fuelType: fuel_type
    transmission
    vehicleType: vehicle_type
    seats
    doors
    height
    width
    length
    customFeatures: custom_features
  }
`;

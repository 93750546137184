export default {
  default: {
    waitingagent: 'Laukiama patvirtinimo',
    cash: 'Grynieji',
    card: 'Kortelė',
    available: 'Laisva',
    reserved: 'Rezervuota',
    rented: 'Nuomojama',
    enabled: 'Įjungta',
    disabled: 'Išjungta',
    success: 'Taip',
    failed: 'Ne',
    true: 'Taip',
    false: 'Ne',
  },
  enabled: {
    true: 'Įjungtas',
    false: 'Išjungtas',
  },
};

import { gql } from '@apollo/client';

export default gql`
  query SmartIDAuth($token: String!) {
    smartIdAuth(token: $token) {
      is_valid
      errors
    }
  }
`;

import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ApolloProvider } from '@apollo/client';
import client from './GraphqlClient';

import './index.scss';

import RoutedApp from '<src>/App/Router';

if (!process.env.DEV || process.env.DEV === 'false') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
    release: process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

function App() {
  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <RoutedApp />
      </ApolloProvider>
    </React.StrictMode>
  );
}

export default App;

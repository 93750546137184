import { gql } from '@apollo/client';

import { CardFragment } from '../fragments';

export default gql`
  query FetchCards {
    cards {
      ...CardFragment
    }
  }
  ${CardFragment}
`;

import { gql } from '@apollo/client';

export default gql`
  mutation createUser(
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
    $phone: String!
    $person_code: String!
    $is_juridical: Boolean!
    $company_name: String
    $company_code: String
    $company_address: String
    $company_vat: String
    $address: String!
    $drivers_license_front: Upload!
    $drivers_license_back: Upload!
  ) {
    createUser(
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
      phone: $phone
      is_juridical: $is_juridical
      company_name: $company_name
      company_code: $company_code
      company_address: $company_address
      company_vat: $company_vat
      person_code: $person_code
      address: $address
      drivers_license_front: $drivers_license_front
      drivers_license_back: $drivers_license_back
    ) {
      id
      email
    }
  }
`;

export default {
  FIELD_REQUIRED: 'Šis laukelis yra būtinas',
  INVALID_EMAIL: 'El.paštas įvestas neteisingai',
  PASSWORDS_MISMATCH: 'Slaptažodžiai nesutampa',
  PASSWORD_MINIMUM_7_LENGTH: 'Slaptažodyje turi būti bent 7 simboliai',
  FIELD_NUMBER: 'Laukelyje turi būti įvestas skaičius',
  FIELD_INTEGER: 'Laukelyje turi būti įvestas sveikasis skaičius',
  VIN_REQUIRED: 'VIN kodas yra būtinas',
  FIELD_FILL_OR_DELETE: 'Užpildykite arba ištrinkite šį laukelį',
};

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Textarea = React.forwardRef(
  (
    { errorClassName, className, value, name, placeholder, rows, error, reducedSize, ...restProps },
    ref
  ) => {
    return (
      <>
        <textarea
          {...restProps}
          name={name}
          placeholder={placeholder}
          rows={rows}
          ref={ref}
          className={cx(
            reducedSize ? 'py-2 px-4' : 'py-3 px-6',
            'mt-6 mb-1 leading-none rounded-200 placeholder-gray-600 text-gray-100 w-full bg-gray-200',
            className
          )}
          defaultValue={value}
        />
        {error && <span className={cx('text-red-200', errorClassName)}>{error.message}</span>}
      </>
    );
  }
);

Textarea.propTypes = {
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  value: PropTypes.string,
  rows: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.shape({ message: PropTypes.string }),
  reducedSize: PropTypes.bool,
};

Textarea.defaultProps = {
  rows: 4,
  value: '',
  placeholder: '',
  reducedSize: false,
};

export default Textarea;

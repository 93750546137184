import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const ALERT_TYPES = {
  success: 'bg-green-100 text-white',
  error: 'bg-red-100 text-red-200',
};

function ContextualAlert({ type, children, className }) {
  return <div className={cx(className, 'py-2 px-10', ALERT_TYPES[type])}>{children}</div>;
}

ContextualAlert.propTypes = {
  type: PropTypes.oneOf(Object.keys(ALERT_TYPES)),
  children: PropTypes.string,
  className: PropTypes.string,
};

export default ContextualAlert;

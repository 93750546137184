import { gql } from '@apollo/client';

import { FinishedTripFragment, UserFragment } from '../fragments';

export default gql`
  query trips(
    $limit: Int
    $page: Int!
    $trip_start: String
    $trip_ended: String
    $firstname: String
    $lastname: String
    $company_name: String
    $vehicle_owner_company: String
    $plate_number: String
    $is_paid: String
    $trip_payment_type: String
  ) {
    trips(
      page: $page
      limit: $limit
      trip_start: $trip_start
      trip_ended: $trip_ended
      firstname: $firstname
      lastname: $lastname
      company_name: $company_name
      vehicle_owner_company: $vehicle_owner_company
      plate_number: $plate_number
      is_paid: $is_paid
      trip_payment_type: $trip_payment_type
    ) {
      total
      perPage: per_page
      currentPage: current_page
      hasMorePages: has_more_pages
      data {
        ...FinishedTripFragment
        user {
          ...UserFragment
        }
        status
      }
    }
  }
  ${FinishedTripFragment}
  ${UserFragment}
`;

import { gql } from '@apollo/client';

import { VehicleOwnerFragment } from '../fragments';

export default gql`
  mutation createVehicleOwner(
    $firstName: String!
    $lastName: String!
    $password: String!
    $companyName: String!
    $companyCode: String!
    $companyAddress: String!
    $companyVATCode: String!
    $email: String!
    $phoneNumber: String!
    $enabled: Boolean!
  ) {
    createVehicleOwner(
      firstname: $firstName
      lastname: $lastName
      password: $password
      email: $email
      phone: $phoneNumber
      company_name: $companyName
      company_code: $companyCode
      company_address: $companyAddress
      company_vat: $companyVATCode
      enabled: $enabled
    ) {
      ...VehicleOwnerFragment
    }
  }
  ${VehicleOwnerFragment}
`;

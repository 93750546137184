import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUserData } from '<app>/hooks';
import user from '<app>/models/user';
import pathnames from '<app>/pathnames';

const allowedRoles = [user.ROLE_CLIENT, undefined];
function PublicRoute({ ...props }) {
  const { role } = useUserData();

  if (!allowedRoles.includes(role)) {
    return role === user.ROLE_VEHICLE_OWNER ? (
      <Redirect to={pathnames.VEHICLE_OWNER_AUTO_LIST} />
    ) : (
      <Redirect to={pathnames.ADMIN_AUTO_LIST} />
    );
  }

  return <Route {...props} />;
}

export default PublicRoute;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUserData } from '<app>/hooks';
import user from '<app>/models/user';
import pathnames from '<app>/pathnames';

const allowedRoles = [user.ROLE_VEHICLE_OWNER];
function VehicleOwnerRoute({ ...props }) {
  const { role } = useUserData();

  if (!allowedRoles.includes(role)) {
    return <Redirect to={pathnames.LANDING} />;
  }

  return <Route {...props} />;
}

export default VehicleOwnerRoute;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './style.scss';

const Dropdown = React.forwardRef(
  (
    { wrapperClassName, className, errorClassName, error, options, placeholder, ...restProps },
    ref
  ) => {
    const [selected, setSelected] = useState(restProps.defaultValue || '');

    const handleChange = (event) => {
      setSelected(event.target.value);
      if (restProps.onChange) {
        restProps.onChange(event);
      }
    };

    return (
      <div className={cx('w-full', wrapperClassName)}>
        <select
          {...restProps}
          onChange={handleChange}
          ref={ref}
          className={cx(
            'py-3 px-6',
            'mb-1 leading-none',
            {
              'text-gray-100': selected,
              'text-gray-600': !selected,
            },
            'w-full',
            className
          )}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error && (
          <span className={cx('text-red-200 text-150', errorClassName)}>{error.message}</span>
        )}
      </div>
    );
  }
);

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  error: PropTypes.shape({ message: PropTypes.string }),
};

export default Dropdown;

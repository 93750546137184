import { gql } from '@apollo/client';

export default gql`
  mutation GenerateInvoice(
    $tripIds: [String!]!
    $email: String
    $invoiceNumber: Int
    $invoiceDate: String
  ) {
    generateInvoice(
      tripIds: $tripIds
      email: $email
      invoiceNumber: $invoiceNumber
      invoiceDate: $invoiceDate
    ) {
      success
      message
      url
    }
  }
`;

import { gql } from '@apollo/client';

import { TripFragment, CommandFragment } from '../fragments';

export default gql`
  mutation LockVehicleMutation {
    lockVehicle {
      trip {
        ...TripFragment
      }
      command {
        ...CommandFragment
      }
    }
  }
  ${TripFragment}
  ${CommandFragment}
`;

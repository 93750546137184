import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';

momentDurationFormat(moment);

const formatSecondsToHours = (seconds) => {
  return moment.duration(seconds, 'seconds').format('HH:mm:ss', { trim: false });
};

export default formatSecondsToHours;
